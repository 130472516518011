import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { affiliate, CheckAffiliateHostedPageApi } from "backendServices/ApiCalls";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";


const AffiliateDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [affLoading, setaffLoading] = useState(false);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  });

  if (loginUserData?.user_type === 'Distributor') {
    navigate("/affiliate-dashboard/2");
  }

  // const handleAgreement = () => {
  //     window.open(
  //         "https://novalya.com/wp-content/uploads/2024/02/Contrat-Affiliation-Novalya-01-Fev-2024-.pdf",
  //         "_blank"
  //     );
  // };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const hostedId = urlSearchParams.get("id");
  const hostedState = urlSearchParams.get("state");

  const handleAgreement = () => {
    window.open(
      "https://novalya.com/wp-content/uploads/2024/02/Contrat-Affiliation-Novalya-01-Fev-2024-.pdf",
      "_blank"
    );
  };

  const handleLink = () => {
    const url = "https://novalya.com/affiliate-compensation/";
    window.open(url, "_blank");
  };

  const onSubmitForm = () => {
    setLoading(true);
    let params = {
      customerid: loginUserData?.customerid,
      item_price_id: 'Affiliate-Fee-EUR-Yearly',
    }
    affiliate(params, (response) => {
      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error"
        })

      }
      else if (response?.data?.status === "success") {
        window.location.replace(response?.data?.data?.hosted_page?.url)

      }
      else {
        setalertData({
          show: true,
          message: 'Something went wrong please try again later',
          variant: "error"
        })
      }
      setLoading(false);
    }, (error) => {
      setLoading(false);
      console.log(error?.response?.data);
    })
  }


  const getCheckAffiliateHostedApi = () => {
    setaffLoading(true);
    let params = {
      hostedId: hostedId,
    };
    CheckAffiliateHostedPageApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          localStorage.setItem("affiliate_status", "yes");
          setalertData({
            show: true,
            message: "You have become an affiliate successfully.",
            variant: "success",
          });
          setaffLoading(false);
          navigate("/affiliate-dashboard/2");
        } else {
          setalertData({
            show: true,
            message: "Server error occurred. Please try again later.",
            variant: "error",
          });
        }
        setaffLoading(false);
      },
      (error) => {
        setaffLoading(false);

      }
    );
  };

  useEffect(() => {
    if (hostedState && hostedId) {
      getCheckAffiliateHostedApi();
    }
  }, []);

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <section className="dash-sec">
        <div className="container">
          <div className="novalya-dashboard-main-warp">
            <div className="novalya-dashboard-warp-left">
              <div className="novalya-warp-workwrap">
                <div
                  className="novalya-warp-work"
                  style={{ "grid-template-columns": "1fr" }}
                >
                  <div className="novalya-warp-work-out">
                    <h2 className="novlay-dash-main-hd">
                      {t("pages.title.how_does_it_work")}
                    </h2>
                    <div className="vide-wrap-nova">
                      <iframe
                        src={t("pages.title.new-dashboard_video")}
                        title="Video"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="novalya-dashboard-warp-right">
                <div
                  className="novalya-dashboard-right"
                  style={{
                    backgroundImage:
                      "url(/images/affiliate-dashbaord-sidearea-bg.svg)",
                    minHeight: "463px",
                    backgroundPosition: "bottom right",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "rgba(94, 59, 183, 0.1)",
                  }}
                >
                  <div className="novalya-dashboard-rightactive">
                    <div className="novalya-rightactive-text">
                      <button
                        className="nova-prospecting install-extension-btn inactive-tag inactive-ext"
                        style={{
                          fontFamily: "'DM Sans'",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "23.44px",
                          letterSpacing: "0.34px",
                          textTransform: "none",
                          border: "3px solid #c9c1ec",
                          outline: "none",
                          margin: "0",
                          width: "100%",
                          marginTop: "35px",
                          background: "#5E3BB7",
                        }}
                        onClick={onSubmitForm}
                        disabled={loading}
                      >
                        {loading ? 'Loading...' : t('Become an Affiliate')}
                      </button>
                    </div>
                    <div className="novalya-rightactive-text">
                      <button
                        className="nova-prospecting install-extension-btn inactive-tag inactive-ext"
                        style={{
                          fontFamily: "'DM Sans'",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "23.44px",
                          letterSpacing: "0.34px",
                          textTransform: "none",
                          outline: "none",
                          margin: "auto",
                          width: "80%",
                          marginTop: "35px",
                          background: "rgba(201, 193, 236, 1)",
                          color: "rgba(94, 59, 183, 1)",
                          border: "1px solid rgba(94, 59, 183, 0.5)",
                        }}
                        onClick={() => handleAgreement()}
                      >
                        {t("Affiliate Agreement")}
                      </button>
                    </div>
                    <div className="novalya-rightactive-text">
                      <button
                        className="nova-prospecting install-extension-btn inactive-tag inactive-ext"
                        style={{
                          fontFamily: "'DM Sans'",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "23.44px",
                          letterSpacing: "0.34px",
                          textTransform: "none",
                          outline: "none",
                          margin: "auto",
                          width: "80%",
                          marginTop: "35px",
                          background: "rgba(201, 193, 236, 1)",
                          color: "rgba(94, 59, 183, 1)",
                          border: "1px solid rgba(94, 59, 183, 0.5)",
                        }}
                        onClick={() => handleLink()}
                      >
                        {t("Compensation Plan")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="novalya-dashboard-warp-left"
              style={{ gridTemplateColumns: "1fr" }}
            >
              <div className="grid-box sales-bonus">
                <h2
                  style={{
                    fontFamily: "'DM Sans'",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "32px",
                    color: "rgba(23, 15, 73, 1)",
                  }}
                >
                  {t("pages.title.MySalesBonus")}
                </h2>
                <p
                  style={{
                    fontFamily: "'DM Sans'",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "rgba(33, 43, 54, 0.8)",
                    marginTop: "15px",
                  }}
                >
                  {t("pages.title.personalactivesalesdetail")}
                </p>
              </div>

              <div className="grid-box">
                <div className="actual-payout">
                  <h3>
                    {t("pages.title.YourActualPayout%isBasedontheFollowing")}:
                  </h3>
                  <div className="NVL-table">
                    <table>
                      <thead>
                        <tr>
                          <th>{t("pages.title.DirectActives")}</th>
                          <th>1</th>
                          <th>3</th>
                          <th>5</th>
                          <th>10</th>
                          <th>15</th>
                          <th>20</th>
                          <th>25</th>
                          <th>30</th>
                          <th>50</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("pages.title.Niveau1")}</td>
                          <td>10%</td>
                          <td>20%</td>
                          <td>22.5%</td>
                          <td>22.5%</td>
                          <td>25%</td>
                          <td>27.5%</td>
                          <td>27.5%</td>
                          <td>30%</td>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <td>{t("pages.title.Niveau2")}</td>
                          <td>0%</td>
                          <td>20%</td>
                          <td>20%</td>
                          <td>22.5%</td>
                          <td>22.5%</td>
                          <td>22.5%</td>
                          <td>25%</td>
                          <td>25%</td>
                          <td>30%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AffiliateDashboard;
