import { useTranslation } from "react-i18next";
import { stepCount } from "..";

export const currentStepValue = {
  blank: 1,
  template: 2,
  ai: 3,
};

export function FirstScreen({
  setStep,
  handleClose,
  currentStep,
  setCurrentStep,
}) {
  const { t } = useTranslation();

  const handleNext = () => {
    if (currentStep === currentStepValue.blank) {
      setStep(stepCount.createScreen);
    } else if (currentStep === currentStepValue.template) {
      setStep(stepCount.listScreen);
    }
  };

  return (
    <div class="ag-msg-popup">
      <div class="ag-msg-popup-inner ag-scroll ag-step-01">
        <div class="ag-msg-popup-content">
          <div class="a-msg-close-btn">
            <button class="version-pop-close-btn" onClick={handleClose}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L1 11M1 1L11 11"
                  stroke="#949494"
                  stroke-width="1.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div class="ag-msg-step-01">
            <div class="ag-msg-temp-select">
              <button
                class={`ag-msg-temp-select-btn ag-select-btn-blue ${
                  currentStep === currentStepValue.blank ? "is_active" : ""
                }`}
                onClick={() => {
                  setCurrentStep(currentStepValue.blank);
                }}
              >
                <span class="ag-msg-template-tag">
                   {t("pages.title.Blank")} 
                </span>
                <span class="ag-msg-template-title">
                  
                  {t("pages.title.Start from a blank page")} 
                </span>
              </button>
              <button
                class={`ag-msg-temp-select-btn ag-select-btn-dark ${
                  currentStep === currentStepValue.template ? "is_active" : ""
                }`}
                onClick={() => {
                  setCurrentStep(currentStepValue.template);
                }}
              >
                <span class="ag-msg-template-tag">{t("pages.title.Use Template")} </span>
                <span class="ag-msg-template-title">
                   {t("pages.title.Over 30 Templates ready to use")}
                </span>
              </button>
              <button
                class="ag-msg-temp-select-btn ag-select-btn-voilet"
                disabled
              >
                <span class="ag-msg-template-tag">{t("pages.title.Ask AI")} </span>
                <span class="ag-msg-template-title"> {t("pages.title.Coming soon")}</span>
              </button>
            </div>
            <button
              class={`ag-lg-btn ag-bg-gray ${currentStep ? "is_active" : ""}`}
              onClick={handleNext}
            >
               
              {t("pages.title.Next")}
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 5.24242V6.75758H9.84848L5.3346 10.9242L6.5 12L13 6L6.5 0L5.3346 1.07576L9.84848 5.24242H0Z"
                  fill="#747474"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
