import { Dialog } from "@mui/material";
import React from "react";
import "./index.css";
import image from "./mrkt-popup-img.png";

export function CommonInfoPopup() {
  const [isOpen, setIsOpen] = React.useState(false);

  // Function to check if popup should show
  const checkPopupStatus = () => {
    const lastClosedDate = localStorage.getItem("popupClosedDate");
    const today = new Date().toISOString().split("T")[0]; // Get only the date (YYYY-MM-DD)
    const language = localStorage.getItem("selectedLocale");
    const onlyShowPopupInFrench = language === "fr-FR" ? true : false;
    if (lastClosedDate !== today && onlyShowPopupInFrench) {
      setIsOpen(true);
    }
  };

  // Function to handle popup close
  const handleClosePopup = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date
    localStorage.setItem("popupClosedDate", today); // Store today's date in localStorage
    setIsOpen(false); // Close the popup
  };

  React.useEffect(() => {
    checkPopupStatus(); // Check popup status on component mount
  }, []);

  const handleClick = () => {
    handleClosePopup();
    window.open("https://www.novalya.ai/flow24", "_blank");
  };

  return (
    <Dialog className="mrkt-pop" open={isOpen} onClose={handleClosePopup}>
      <div class="mrkt-pop-main">
        <div class="mrkt-pop-warper">
          <div class="mrkt-pop-content">
            <button class="mrkt-pop-close" onClick={handleClosePopup}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L1 11M1 1L11 11"
                  stroke="#949494"
                  stroke-width="1.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div class="mrkt-pop-imgWrap">
              <div class="mrkt-pop-img">
                <img src={image} alt="" />
              </div>
              <div class="mrkt-footer">
                <button onClick={handleClick} class="mrkt-popup-btn">
                  EN SAVOIR PLUS
                </button>
                <a
                  class="mrkt-blue-text"
                  href="https://www.novalya.ai/flow24"
                  target="_blank"
                  rel="noreferrer"
                >
                  Je suis déjà inscrit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
